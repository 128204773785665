import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { AppsettingsService } from '../appsettings.service';

@Injectable({
  providedIn: 'root'
})
export class AppinitService {
  userClaimsInfo: any;
  utilitySettings: any;
  userRoles: string[] = [];
  constructor(public oidcService: OidcSecurityService,
    private authService: AuthService,
    private router: Router,
    private appsettings: AppsettingsService)  { }

  verifyUserAuthentication(dataCode) {
    return new Promise((resolve, reject) => {

      this.oidcService.checkAuth().subscribe(({ isAuthenticated, userData, accessToken, idToken }) => {
        if (isAuthenticated) {

          this.authService.getToken().subscribe({
            next: (token) => {
              const decodedToken: any = jwtDecode(token);
              this.userRoles = decodedToken.role || [];
              console.log('User Roles:', this.userRoles);

            },
            error: (err) => {
              console.error('Error retrieving token:', err);
            },
          });
          this.authService.userinfo().subscribe({
            next: (userInfo) => {
              this.userClaimsInfo = userInfo; 
            },
            error: (error) => {
              console.error('Error fetching user info:', error);
            }
          });

          this.utilitySettings = this.userClaimsInfo;
          this.appsettings.setUserProfile(this.utilitySettings);
          this.router.navigate(['admin/manage']);
          return resolve(true);
        }
        else {
          this.authService.login();
        }
      }, error => {
        this.authService.login();
      }
      );

    })

  }

}
