import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AbstractSecurityStorage, AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { AppRoutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { VerticalAppHeaderComponent } from './layouts/full/vertical-header/vertical-header.component';
import { SpinnerComponent } from './shared/spinner.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { VerticalAppSidebarComponent } from './layouts/full/vertical-sidebar/vertical-sidebar.component';
import { AppBreadcrumbComponent } from './layouts/full/breadcrumb/breadcrumb.component';
import { HorizontalAppHeaderComponent } from './layouts/full/horizontal-header/horizontal-header.component';
import { HorizontalAppSidebarComponent } from './layouts/full/horizontal-sidebar/horizontal-sidebar.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MaterialModule } from './material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { FooterComponent  } from './layouts/full/footer/footer.component'
import { AuthService } from './core/services/auth/auth.service';
import { AuthLocalStorage } from './core/services/common/AuthLocalStorage';
import { AppinitService } from './core/services/common/appinit.service';
import { TokenInterceptor } from './core/interceptors/http.token.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export const WINDOW = new InjectionToken<Window>('WindowToken');

export function getWindow(): Window {
  return window;
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};
@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    VerticalAppHeaderComponent,
    SpinnerComponent,
    AppBlankComponent,
    VerticalAppSidebarComponent,
    AppBreadcrumbComponent,
    HorizontalAppHeaderComponent,
    HorizontalAppSidebarComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    //PerfectScrollbarModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
   // FlexLayoutModule,
    ToastrModule.forRoot(),
 
    MaterialModule,
    RouterModule.forRoot(AppRoutes),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AuthModule.forRoot({
      config: {
        authority: environment.identityUrl,
        redirectUrl: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        clientId: environment.clientID,
        scope: 'openid profile email MyClientId_api roles', 
        responseType: 'code',
        useRefreshToken: true,
        logLevel: (environment.production) ? LogLevel.Error : LogLevel.Debug,
        renewTimeBeforeTokenExpiresInSeconds: Math.floor(Math.random() * 400) + 100,
        silentRenew: true,
        ignoreNonceAfterRefresh:true,
       // storage: new AuthLocalStorage()
      },
    }),
  ],
  providers: [{

    provide: PERFECT_SCROLLBAR_CONFIG,
    useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
  },
    //{
    //  provide: HTTP_INTERCEPTORS,
    //  useClass: TokenInterceptor,
    //  multi: true,
    //},
    {
      provide: APP_INITIALIZER,
      useFactory:
        (myInitService: AppinitService) => () => myInitService.verifyUserAuthentication(null),
      deps: [AppinitService],
      multi: true
    },
    AuthService,
    { provide: WINDOW, useFactory: getWindow },
    { provide: AbstractSecurityStorage, useClass: AuthLocalStorage },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
