export const environment = {
  production: false,
  url: "https://ps-stage.backpack.education",
  appUrl: "https://ps-stage.backpack.education",
  identityUrl: "https://iddemo.backpack.education",
  //identityUrl: "https://localhost:44310",
  clientID: "PsychometricStudio_stage",
  IdleTimeOutInMin: 60,
  IdleTimeOutPopupInSec: 60,
  appinsights_instrumentationkey: "1f57bb19-6a93-46c7-83e9-7ac801e87d61" // Change this key based on the environment

};
