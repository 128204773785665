import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: '/admin/manage',
        pathMatch: 'full'
      },
      {
        path: 'admin',
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
      },
      {
        path: 'analysis',
        loadChildren: () => import('./modules/analysis/analysis.module').then(m => m.AnalysisModule)
      },
      {
        path: 'scoring',
        loadChildren: () => import('./modules/scoring/scoring.module').then(m => m.ScoringModule)
      },
    ]
  }
];

