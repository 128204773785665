import { Injectable } from '@angular/core';

export interface BadgeItem {
    type: string;
    value: string;
}
export interface Saperator {
    name: string;
    type?: string;
}
export interface SubChildren {
    state: string;
    name: string;
    type?: string;
}
export interface ChildrenItems {
    state: string;
    name: string;
    type?: string;
    child?: SubChildren[];
}

export interface Menu {
    state: string;
    name: string;
    type: string;
    icon: string;
    badge?: BadgeItem[];
    saperator?: Saperator[];
    children?: ChildrenItems[];
}

const MENUITEMS = [
    // {
    //     state: '',
    //     name: 'Personal',
    //     type: 'saperator',
    //     icon: 'av_timer'
    // },
    {
    state: 'admin',
        name: 'Administration',
        type: 'sub',
        icon: 'admin_panel_settings',
        code:'Admin',
        children: [
          { state: 'manage', name: 'Add/Select', type: 'link',code:'Admin' },
          { state: 'scalescuts', name: 'Scales and Cuts', type: 'link', code: 'Admin' }
        ]
  },
  {
    state: 'analysis',
    name: 'Analysis',
    type: 'sub',
    icon: 'trending_up',
    code: 'Admin',
    children: [
      { state: 'manage', name: 'Add/Select', type: 'link', code: 'Admin' },
      { state: 'analyze', name: 'Analyze', type: 'link',code:'Analyze' }
    ]
  },
  {
    state: 'scoring',
    name: 'Scoring',
    type: 'sub',
    icon: 'speed',
    code: 'Admin',
    children: [
      { state: 'manage', name: 'Create Lookup Tables', type: 'link', code: 'Admin' },
      { state: 'score', name: 'Score', type: 'link', code: 'Score' }
    ]
  },
   
];

@Injectable()
export class MenuItems {
    getMenuitem(): Menu[] {
        return MENUITEMS;
    }
}
