import { Inject, Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { WINDOW } from '../../../app.module';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BehaviorSubject<any> {
  public currentUser: Observable<any>;
  public getLoggedInName = new Subject();
  private currentUserSubject = new BehaviorSubject<boolean>(false);
  constructor(public oidcSecurityService: OidcSecurityService,
    @Inject(WINDOW) private window: Window,) {
    super("");
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public getToken(): Observable<string | null> {
    return this.oidcSecurityService.getAccessToken();
  }

  public loginUserinfo() {
    this.currentUserSubject.next(true);
  }


  public userinfo(): Observable<any> {
    return this.oidcSecurityService.getUserData();
  }

  public login() {
    this.oidcSecurityService.authorize();
  }

  public logoff() {
    this.oidcSecurityService.checkAuth().subscribe(({ isAuthenticated, userData, accessToken, idToken }) => {
      if (isAuthenticated) {
        history.pushState(null, null, "/");
        this.oidcSecurityService.logoff().subscribe((result) => {});
        this.window.sessionStorage.clear();
        localStorage.clear();
      }
      else {
        //window.location.href = environment.identityUrl + "/Account/Logout";
        this.login();
      }
    });
    //this.router.navigate(['/']);
  }
}
