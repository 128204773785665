<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container" [dir]="dir"
  [ngClass]="{'minisidebar': minisidebar, 'boxed': boxed, 'danger': danger, 'blue': blue, 'green': green, 'dark': dark,  'horizontal' : horizontal }">
  <!-- ============================================================== -->
  <!-- Topbar - style you can find in header.scss -->
  <!-- ============================================================== -->
  <mat-toolbar color="primary" class="topbar telative">
    <div class="w-100 fix-width-for-horizontal">
      <!-- ============================================================== -->
      <!-- Logo - style you can find in header.scss -->
      <!-- ============================================================== -->
      <div class="navbar-header">
        <a class="navbar-brand" href="/">
          <!-- Logo icon -->
          <b>
            <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
            <!-- Dark Logo icon -->
            <!-- <img src="assets/images/logo-icon.png" alt="homepage" class="dark-logo"> -->
            <!-- Light Logo icon -->
            <img src="assets/images/Psychomatric_logo.png" alt="homepage" class="light-logo">
          </b>
          <!--End Logo icon -->
          <!-- Logo text -->

        </a>
      </div>
      <!-- ============================================================== -->
      <!-- sidebar toggle -->
      <!-- ============================================================== -->

      <button mat-icon-button (click)="snav.toggle() && clickEvent()" class="sidebar-toggle" value="sidebarclosed">
        <mat-icon>menu</mat-icon>
      </button>
      <div fxFlex class="header-title" *ngIf="tenantName">
        <span>
          <span class="page__main__title" [matTooltip]="tenantName" matTooltipPosition="below">
            {{ tenantName }}
          </span>
        </span>
      </div>
      <!-- ============================================================== -->
      <!-- Search - style you can find in header.scss -->
      <!-- ============================================================== -->
      <!--<button mat-icon-button class="srh-btn" (click)="showSearch = !showSearch">
        <mat-icon>search</mat-icon>
      </button>-->
      <span class="flex-1-auto"></span>
      <div class="header__right_wrapper">
        <div class="col-sm-6 d-flex align-items-center ">
          <div class="tenant_dropdown">
            <mat-label class="mat-subtitle-2 mat-label f-w-600">Select Tenant</mat-label>
            <mat-form-field appearance="outline" class="space">
              <mat-select [formControl]="Tenant" #select placeholder="Select Tenant" (selectionChange)="onTenantChange($event)">
                <mat-option *ngFor="let tenant of tenants" [value]="tenant.id">{{ tenant.customerName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <!-- <a class="notification_icon">
     <span>
       <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
         <g clip-path="url(#clip0_2009_2559)">
           <path d="M9.15356 20C7.43115 20 6.02856 18.5983 6.02856 16.875C6.02856 16.53 6.30856 16.25 6.65356 16.25C6.99857 16.25 7.27856 16.53 7.27856 16.875C7.27856 17.9092 8.12024 18.75 9.15356 18.75C10.1869 18.75 11.0286 17.9092 11.0286 16.875C11.0286 16.53 11.3086 16.25 11.6536 16.25C11.9986 16.25 12.2786 16.53 12.2786 16.875C12.2786 18.5983 10.8761 20 9.15356 20Z"
                 fill="white" />
           <path d="M16.0286 17.5H2.27859C1.47446 17.5 0.820312 16.8459 0.820312 16.0417C0.820312 15.6149 1.00616 15.2109 1.33026 14.9333C1.35117 14.915 1.3736 14.8984 1.39694 14.8833C2.62024 13.8158 3.32031 12.28 3.32031 10.6583V8.33328C3.32031 5.11673 5.93781 2.5 9.15359 2.5C9.28696 2.5 9.43115 2.50244 9.56451 2.52502C9.90524 2.58163 10.1353 2.90421 10.0786 3.24417C10.022 3.58414 9.6936 3.81424 9.35944 3.75748C9.29276 3.74664 9.21951 3.75 9.15359 3.75C6.62704 3.75 4.57031 5.80582 4.57031 8.33328V10.6583C4.57031 12.6717 3.68774 14.5767 2.15118 15.8841C2.13867 15.8942 2.12784 15.9033 2.11441 15.9125C2.09198 15.9409 2.07031 15.9842 2.07031 16.0417C2.07031 16.1549 2.16537 16.25 2.27859 16.25H16.0286C16.142 16.25 16.237 16.1549 16.237 16.0417C16.237 15.9833 16.2154 15.9409 16.192 15.9125C16.1795 15.9033 16.1687 15.8942 16.1562 15.8841C14.6187 14.5758 13.737 12.6717 13.737 10.6583V9.75006C13.737 9.40506 14.017 9.12506 14.362 9.12506C14.707 9.12506 14.987 9.40506 14.987 9.75006V10.6583C14.987 12.2809 15.6879 13.8174 16.9128 14.8859C16.9353 14.9008 16.9569 14.9167 16.9769 14.9342C17.3012 15.2109 17.487 15.6149 17.487 16.0417C17.487 16.8459 16.8329 17.5 16.0286 17.5Z"
                 fill="white" />
           <path d="M14.987 8.33328C12.6895 8.33328 10.8203 6.46423 10.8203 4.16672C10.8203 1.8692 12.6895 0 14.987 0C17.2845 0 19.1536 1.8692 19.1536 4.16672C19.1536 6.46423 17.2845 8.33328 14.987 8.33328ZM14.987 1.25C13.3786 1.25 12.0703 2.55829 12.0703 4.16672C12.0703 5.77499 13.3786 7.08328 14.987 7.08328C16.5953 7.08328 17.9036 5.77499 17.9036 4.16672C17.9036 2.55829 16.5953 1.25 14.987 1.25Z"
                 fill="white" />
         </g>
         <defs>
           <clipPath id="clip0_2009_2559">
             <rect width="20" height="20" fill="white" />
           </clipPath>
         </defs>
       </svg>

     </span>
   </a>-->
        <!--<a class="logout_icon">
    <span class="svg_logout">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.04028 21.75H13.0412C13.6777 21.75 14.2883 21.4866 14.7384 21.0178C15.1885 20.5489 15.4414 19.913 15.4414 19.25V18.4167C15.4414 18.1957 15.3571 17.9837 15.2071 17.8274C15.057 17.6711 14.8535 17.5833 14.6413 17.5833C14.4291 17.5833 14.2256 17.6711 14.0756 17.8274C13.9255 17.9837 13.8412 18.1957 13.8412 18.4167V19.25C13.8412 19.471 13.757 19.683 13.6069 19.8393C13.4569 19.9955 13.2534 20.0833 13.0412 20.0833H5.04028C4.82808 20.0833 4.62458 19.9955 4.47453 19.8393C4.32449 19.683 4.24019 19.471 4.24019 19.25V4.25C4.24019 4.02899 4.32449 3.81702 4.47453 3.66074C4.62458 3.50446 4.82808 3.41667 5.04028 3.41667H13.0412C13.2534 3.41667 13.4569 3.50446 13.6069 3.66074C13.757 3.81702 13.8412 4.02899 13.8412 4.25V5.08333C13.8412 5.30435 13.9255 5.51631 14.0756 5.67259C14.2256 5.82887 14.4291 5.91667 14.6413 5.91667C14.8535 5.91667 15.057 5.82887 15.2071 5.67259C15.3571 5.51631 15.4414 5.30435 15.4414 5.08333V4.25C15.4414 3.58696 15.1885 2.95107 14.7384 2.48223C14.2883 2.01339 13.6777 1.75 13.0412 1.75H5.04028C4.40369 1.75 3.79317 2.01339 3.34304 2.48223C2.8929 2.95107 2.64001 3.58696 2.64001 4.25V19.25C2.64001 19.913 2.8929 20.5489 3.34304 21.0178C3.79317 21.4866 4.40369 21.75 5.04028 21.75Z"
              fill="white" />
        <path d="M18.8739 7.62519C18.7208 7.48866 18.5239 7.41733 18.3226 7.42543C18.1212 7.43353 17.9302 7.52047 17.7877 7.66888C17.6452 7.8173 17.5617 8.01624 17.554 8.22597C17.5462 8.4357 17.6147 8.64077 17.7457 8.80019L19.77 10.9169H8.2407C8.0285 10.9169 7.825 11.0046 7.67495 11.1609C7.52491 11.3172 7.44061 11.5292 7.44061 11.7502C7.44061 11.9712 7.52491 12.1832 7.67495 12.3394C7.825 12.4957 8.0285 12.5835 8.2407 12.5835H19.77L17.7457 14.7002C17.5967 14.8563 17.5131 15.0675 17.5131 15.2877C17.5131 15.5078 17.5967 15.7191 17.7457 15.8752C17.8957 16.0304 18.0984 16.1175 18.3098 16.1175C18.5212 16.1175 18.724 16.0304 18.8739 15.8752L21.1381 13.5169C21.5876 13.0481 21.8401 12.4127 21.8401 11.7502C21.8401 11.0877 21.5876 10.4523 21.1381 9.98352L18.8739 7.62519Z"
              fill="white" />
      </svg>

    </span>
  </a>-->

        <button mat-icon-button class="m-r-21" style="bottom: 0px;" (click)="Logout()">
          <span class="material-icons" matTooltip="Logout">logout</span>
        </button>
        <form class="app-search" [ngClass]="{'show-search': showSearch}">
          <input type="text" class="form-control" placeholder="Search &amp; enter">
          <a class="cl-srh-btn" (click)="showSearch = !showSearch">
            <i class="ti-close"></i>
          </a>
        </form>


        <span fxFlex></span>
        <!-- ============================================================== -->
        <!-- app header component - style you can find in header.scss / header.component.ts-->
        <!-- ============================================================== -->
        <!-- <div *ngIf="!horizontal else horizontalheader">
    <app-vertical-header></app-vertical-header>
  </div> -->

        <ng-template #horizontalheader>
          <app-horizontal-header></app-horizontal-header>
        </ng-template>

        <!-- ============================================================== -->
        <!-- Right sidebar toggle - style you can find in rightsidebar.component.scss -->
        <!-- ============================================================== -->
        <button (click)="end.toggle()" mat-icon-button class="ml-xs overflow-visible">
          <mat-icon>settings</mat-icon>
        </button>
      </div>
    </div>

  </mat-toolbar>
  <!-- ============================================================== -->
  <!-- End Topbar - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
    [ngClass]="{'minisidebar': minisidebar}">
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <mat-sidenav #snav id="snav" class="pl-xs" [mode]="mobileQuery.matches ? 'side' : 'over'" fixedTopGap="0"
                 [opened]="mobileQuery.matches" [disableClose]="mobileQuery.matches" (open)="sidebarOpened = true"
                 (close)="sidebarOpened = false">
      @if(horizontal1()){
      <div class="user-profile">
        <!-- User profile image -->
        <div class="profile-img">
          <img src="assets/images/user_logo.png" alt="user">
        </div>
        <!-- User profile text-->
        <!-- ============================================================== -->
        <!-- Profile - style you can find in header.scss -->
        <!-- ============================================================== -->
        <h2 class="user_name">{{appsettingsService.userProfile.name}}</h2>
      </div>
      }
      <!--<perfect-scrollbar *ngIf="!horizontal; else horizontalsidebar" class="scbar" [config]="config">
    <app-vertical-sidebar class="app-sidebar"></app-vertical-sidebar>
  </perfect-scrollbar>-->
      <app-vertical-sidebar class="app-sidebar"></app-vertical-sidebar>
      <!--<ng-template #horizontalsidebar>
    <app-horizontal-sidebar></app-horizontal-sidebar>
  </ng-template>-->


    </mat-sidenav>
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Right Sidebar - style you can find in rightsidebar.component.scss -->
    <!-- ============================================================== -->
    <mat-sidenav #end position="end" class="chat-panel" mode="over" opened="false">
      <div class="scroll">
        <mat-nav-list class="settings_side_menu">

          <h3 mat-subheader class="text-uppercase font-weight-bold">Settings</h3>
          <mat-list-item *ngIf="!minisidebar">
            <mat-slide-toggle color="warn" [(ngModel)]="horizontal" (change)="horizentalBarUpdation()">
              Horizontal Layout</mat-slide-toggle>
          </mat-list-item>
          <mat-list-item>
            <mat-slide-toggle color="warn" (change)="dir = (dir == 'rtl' ? 'ltr' : 'rtl')">RTL</mat-slide-toggle>
          </mat-list-item>
          <mat-list-item>
            <mat-slide-toggle color="warn" [(ngModel)]="boxed">Boxed Layout</mat-slide-toggle>
          </mat-list-item>
          <mat-list-item *ngIf="!horizontal">
            <mat-slide-toggle [(ngModel)]="minisidebar">Mini Sidebar</mat-slide-toggle>
          </mat-list-item>
          <mat-divider></mat-divider>
          <h3 mat-subheader class="text-uppercase font-weight-bold">Colors</h3>
          <mat-list-item>
            <mat-checkbox color="warn" [(ngModel)]="danger" (change)="green = blue = false" class="text-danger">Red
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox color="warn" [(ngModel)]="green" (change)="danger = blue = false" class="text-megna">Teal
              Green</mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox color="warn" [(ngModel)]="blue" (change)="green = danger = false" class="text-info">Blue
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox color="warn" [(ngModel)]="dark" (change)="green = blue = danger = false">Dark</mat-checkbox>
          </mat-list-item>
        </mat-nav-list>
      </div>
    </mat-sidenav>

    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-content class="page-wrapper" [ngClass]="status ? 'minitoggle' : ''">

      <app-breadcrumb></app-breadcrumb>
      <div class="page-content">
        <div class="page_layout_shadow">
          <router-outlet>
            <app-spinner></app-spinner>
          </router-outlet>
        </div>
      </div>
      <app-footer></app-footer>
    </mat-sidenav-content>
    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
  </mat-sidenav-container>
</div>
