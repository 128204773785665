
<mat-menu #flags="matMenu" class="mymegamenu">
    <button mat-menu-item *ngFor="let lang of languages" (click)="changeLanguage(lang)">
        <i class="flag-icon flag-icon-{{lang.icon}}"></i>
        <span>{{lang.language}}</span>
    </button>
</mat-menu>

<!-- ============================================================== -->
<!-- Notification - style you can find in header.scss -->
<!-- ============================================================== -->

<mat-menu #notification="matMenu" class="mymegamenu">
    <div class="mailbox">
        <ul>
            <li>
                <div class="drop-title">Notifications</div>
            </li>
            <li>
                <div class="message-center">
                    <!--<perfect-scrollbar [config]="config">-->
                        <!-- Message -->
                        <!--<a href="#" *ngFor="let notification of notifications">
                            <div class="round {{notification.round}}">
                                <i class="{{notification.icon}}"></i>
                            </div>
                            <div class="mail-content">
                                <h5>{{notification.title}}</h5>
                                <span class="mail-desc">{{notification.subject}}</span>
                                <span class="time">{{notification.time}}</span>
                            </div>
                        </a>

                    </perfect-scrollbar>-->
                </div>
            </li>

        </ul>
    </div>
</mat-menu>
<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->


<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5">
    <img src="assets/images/users/1.jpg" alt="user" class="profile-pic"> </button>
<mat-menu #profile="matMenu" class="mymegamenu">
    <button mat-menu-item>
        <mat-icon>settings</mat-icon> Settings
    </button>
    <button mat-menu-item>
        <mat-icon>account_box</mat-icon> Profile
    </button>
    <button mat-menu-item>
        <mat-icon>notifications_off</mat-icon> Disable notifications
    </button>
    <button mat-menu-item>
        <mat-icon>exit_to_app</mat-icon> Sign Out
    </button>
</mat-menu>
