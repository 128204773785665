
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  Output,
  signal


} from '@angular/core';
import { MenuItems } from '../../shared/menu-items/menu-items';



import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { FormControl } from '@angular/forms';
import { AuthService } from '../../core/services/auth/auth.service';
import { WINDOW } from '../../app.module';
import { ApiService } from '../../core/services/common/api.service';
import { AppsettingsService } from '../../core/services/appsettings.service';

/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: []
})
export class FullComponent implements OnDestroy {
  mobileQuery: MediaQueryList;
  dir: 'ltr' | 'rtl' | 'auto' = 'ltr';
  green = false;
  blue = false;
  dark = false;
  minisidebar = false;
  boxed = false;
  danger = false;
  showHide = false;
  horizontal = false;
  url = '';
  sidebarOpened = false;
  status = false;
  Tenant = new FormControl('');
  tenants: any = [];
  horizontal1 = signal(true);
  tenantName: string | null = null;

  public showSearch = false;

  public config: PerfectScrollbarConfigInterface = {};
  private _mobileQueryListener: () => void;

  clickEvent() {
    this.status = !this.status;
  }

  constructor(
    public router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private apiService: ApiService,
    public appsettingsService: AppsettingsService,
    private authService: AuthService,
    @Inject(WINDOW) private window: Window
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 1023px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener);
    
  }
  ngOnInit(): void {
    this.getCustomerList();
    this.Tenant.valueChanges.subscribe((selectedTenantId) => {

      const selectedTenant = this.tenants.find(tenant => tenant.id === selectedTenantId);
      if (selectedTenant) {
        this.tenantName = selectedTenant.customerName;
        this.appsettingsService.setSelectedTenant(selectedTenant);
      } else {
        this.tenantName = null; 
      }
    });

  }
  ngOnDestroy(): void {
    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener);
    
  }
  getCustomerList() {
    this.apiService.getCustomerList().subscribe(res => {
      this.tenants = res; 
      this.Tenant.setValue(this.tenants[0]?.id);  
    });
  }
  onTenantChange(event: any) {
    const selectedTenantId = event.value;
    const selectedTenant = this.tenants.find((tenant) => tenant.id === selectedTenantId);
    if (selectedTenant) {
      this.appsettingsService.setSelectedTenant(selectedTenant);
     
    }
  }
  Logout = function () {
    this.authService.logoff();
    this.window.sessionStorage.clear();
    localStorage.clear();
  }
  horizentalBarUpdation() {
    if (this.horizontal) {
      this.horizontal1.set(!this.horizontal1);
    } else {
      this.horizontal1.set(true);
    }
  }


  // Mini sidebar
}
