import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppsettingsService {
  private selectedTenantSource = new BehaviorSubject<any>(null);
  selectedTenant$ = this.selectedTenantSource.asObservable();
  userProfile: any;
  constructor() { }
  setSelectedTenant(tenant: any) {
    this.selectedTenantSource.next(tenant);
  }
  getUserProfile() {
    var data = localStorage.getItem('appSettings');
    this.userProfile = Object.assign({}, JSON.parse(data))
    return this.userProfile;
  }

  setUserProfile(data: any) {
    localStorage.setItem('appSettings', JSON.stringify(data));

    this.getUserProfile();
  }
}
